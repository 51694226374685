<template>
  <div class="p-grid p-jc-center p-mt-2">
    <router-link to="/admin/web">
      <Button class="p-col p-mr-1 p-button-success" label="Set Website" />
    </router-link>

    <router-link to="/admin/time">
      <Button class="p-col p-mr-1 p-button-success" label="Set Waktu" />
    </router-link>

    <router-link to="/admin/addResult">
      <Button class="p-col p-mr-1" label="Tambah Result" />
    </router-link>

    <router-link to="/admin/dataResult">
      <Button class="p-col p-mr-1" label="Data Result" />
    </router-link>

    <router-link to="/admin/result">
      <Button style="height: 100%" class="p-col p-mr-1" label="Result" />
    </router-link>

    <!-- <router-link to="/admin/admin">
      <Button
        style="height: 100%"
        class="p-col p-mr-1 p-button-danger"
        label="Admin"
      />
    </router-link> -->

    <router-link to="/" target="_blank">
    <Button
      class="p-col p-mr-1 p-button-danger"
      label="View Website"
    />
    </router-link>
    <router-link to="/login">
      <Button
        style="height: 100%"
        class="p-col p-mr-1 p-button-danger"
        label="Keluar"
        @click="clean()"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  methods: {
    viewWeb() {
      window.open("http://www.bogorpools.com", "_blank");
    },
    clean(){
      localStorage.clear();
      this.$store.commit('setUserName',null);
      this.$store.commit('setUserId',null);
    }
  },
};
</script>

<style>
</style>