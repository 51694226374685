<template>
  <div class="p-d-flex p-py-3 p-px-2 bGrad">
    <router-link
      to="/"
      tag="img"
      class="p-col-12 p-md-2"
      width="150px"
      height="150px"
      style="object-fit: contain; cursor: pointer"
      :src="require('@/assets/bogorpools.png')"
    >
    </router-link>
    <!-- <div class="p-grid"> -->
    <div class="p-col-12 p-md-10">
      <div class="p-d-flex p-mb-3 p-jc-end">
        <!-- <img
          width="50px"
          height="50px"
          style="object-fit: contain"
          :src="require('@/assets/contact.png')"
        /> -->
        <div class="userbox p-px-3 p-py-2">
          <i class="pi pi-user" style="font-size: 2rem" />
        </div>
        <div class="p-mx-3">
          <div class="p-d-flex p-mb-3">
            <div>welcome</div>
            <div class="p-mx-3">:</div>
            <div class="p-text-bold">{{ getUserName }}</div>
          </div>

          <div class="p-d-flex">
            <div>Login Terakhir</div>
            <div class="p-mx-3">:</div>
            <div class="p-text-bold">{{ getNow() }}</div>
          </div>
        </div>
      </div>
      <div class="p-d-flex p-jc-end">
        <router-link to="/admin/change-password">
          <Button class="p-button-secondary" label="Change Password" />
        </router-link>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  computed: {
    getUserName() {
      return this.$store.state.username;
    },
  },
  methods: {
    getNow() {
      let dt = new Date();
      return dt.toLocaleString();
    },
  },
};
</script>

<style>
</style>