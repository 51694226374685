<template>
  <div class="p-py-3" style="color:white;">
    <div class="p-jc-center p-d-flex">All Right Reserved BogorPools 2021</div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>