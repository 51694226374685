<template>
  <div id="app" class="p-lg-8 p-col-12 p-shadow-24 p-px-3 p-my-3">
    <SetHeader />
    <div class="p-p-3" style="background-color: white">
      <set-button />
      <Divider />
      <router-view class="p-px-3" />
    </div>
    <SetFooter />
    <Toast/>
  </div>
</template>

<script>
import SetHeader from "@/components/backoffice/SetHeader.vue";
import SetFooter from "@/components/backoffice/SetFooter.vue";
import SetButton from "@/components/backoffice/SetButton.vue";

export default {
  name: "Home",
  components: {
    SetHeader,
    SetFooter,
    SetButton,
  },
  data() {
    return {
      activeIndex: 0,
      draw_data: [],
    };
  },
  methods: {
    async getResult() {
      await this.$http.get("/result").then((r) => (this.draw_data = r.data));
    },
  },
  mounted() {
    this.getResult();
  },
};
</script>
<style lang="scss">
@import '../admin.scss';
</style>